// Here you can add other styles
.addItemBtn {
    margin-left: 10px;
}

.actionIcons {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

.pageEditIcon {
    margin: 5px;
    cursor: pointer;
}

.orderTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

a.c-brand-logo:hover {
    text-decoration: none;
}

.c-header-logo img {
    mix-blend-mode: darken;
}

.scrollTable {
    max-height: 333px;
    overflow: scroll;
    display: inline-block;
}

.tableHeaderBar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.importExport {
    display: flex;
    flex-wrap: wrap;
}

.importData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100px;
}

.btn-sm-order {
    padding: 0.125rem 0.3rem;
    border-radius: 1.2rem;
}

.rlink {
    vertical-align: middle;
    text-decoration: none;
    margin: 2 auto;
}

.rlink:hover {
    text-decoration: none;
}

.color_inherit {
    color: inherit;
    &:hover {
        color: inherit;
    }
}

.user_avatar_wrapper {
    width: fit-content;
    background: #f0f0f0;
    height: fit-content;
    padding: 0.4rem 0.5rem;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all .3s;
    font-weight: 600;
    img {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        object-fit: contain;
        background: transparent;
    }
    &:hover {
        background: #e7e8ee;
    }
}

.mr_05 {
    margin-right: 0.5rem !important;
}

.ml_05 {
    margin-left: 0.5rem !important;
}

// for status messages in layout page...
.info_messsage_wrapper {
    display: none;
    width: fit-content;
    z-index: 13000;
    position: fixed;
    top: 1rem;
    // left: 0;
    right: 0;
    .message_box_wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 1rem;
    }
}